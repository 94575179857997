import React from 'react'

import SVGIcon from '~/components/SVGIcon'

import './Loader.scss'

export default function Loader () {
  return (
    <div className='loader error-message'>
      <span className='icon-container notice-info'>
        <SVGIcon icon='replay' className='spin' />
      </span>
      <span className='text-container notice-info'>
        Loading...
      </span>
    </div>
  )
}

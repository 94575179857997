import creativeArts from '~/images/course-category-images/GettyImages-153531310.webp'
import insurance from '~/images/course-category-images/GettyImages-183427329.webp'
import aeroDroneTech from '~/images/course-category-images/GettyImages-847857634.webp'
import realEstate from '~/images/course-category-images/GettyImages-1470005513.webp'
import health from '~/images/course-category-images/GettyImages-1490665620.webp'
import financialAndInvestment from '~/images/course-category-images/GettyImages-1487894858.webp'
import culinary from '~/images/course-category-images/GettyImages-1370529871.webp'
import construction from '~/images/course-category-images/GettyImages-961452318.webp'
import accounting from '~/images/course-category-images/GettyImages-1316693862.webp'
import language from '~/images/course-category-images/GettyImages-912751940.webp'
import computers from '~/images/course-category-images/GettyImages-1169699937.webp'
import education from '~/images/course-category-images/GettyImages-1130260081.webp'
import filmMusic from '~/images/course-category-images/GettyImages-512923229.webp'
import hobby from '~/images/course-category-images/GettyImages-1460252250.webp'
import leadership from '~/images/course-category-images/GettyImages-1798400284.webp'
import safety from '~/images/course-category-images/GettyImages-1751342958.webp'
import transportation from '~/images/course-category-images/GettyImages-1348631007.webp'
import manufacturing from '~/images/course-category-images/GettyImages-1733213694.webp'

export const imageMap = {
  AerospaceDroneTechnologies: aeroDroneTech,
  ArchitectureConstruction: construction,
  BusinessAndAccounting: accounting,
  ComputerTechnologies: computers,
  CreativeArts: creativeArts,
  CulinaryDietaryFoodService: culinary,
  EducationAndTraining: education,
  FinancialAndInvestment: financialAndInvestment,
  Health: health,
  Insurance: insurance,
  Languages: language,
  LeadershipProfessionalSkills: leadership,
  Manufacturing: manufacturing,
  RealEstate: realEstate,
  SafetyAndSecurity: safety,
  Transportation: transportation,
  GeneralInterest: hobby,
  FilmMusic: filmMusic
}

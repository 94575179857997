import { initialTime, timeAfterExtending, timeForCheckout, skew } from '~/constants/timer'

export const initialState = {
  // Base values
  publicId: null,
  reservations: [],
  classes: [],
  expiresAt: null,
  extensions: 0,

  // Computed values
  total: 0,
  reservationPublicIds: {},
  sqCampuses: {},
  students: {},
  reservationCountByType: {},
  reservationsByType: {
    SQ: [],
    WEDOE: []
  },
  classesByType: {
    SQ: [],
    WEDOE: []
  }
}

const rebuildState = (publicId, classes, reservations, { expiresAt, extensions }) => {
  if (reservations && reservations.length > 0) {
    expiresAt = expiresAt == null ? Date.now() + initialTime - skew : expiresAt
  } else {
    expiresAt = null
  }

  let state = reservations.reduce((acc, reservation) => {
    acc.total += reservation.section.price
    acc.reservationPublicIds[reservation.publicId] = true
    acc.students[reservation.studentPublicId] = true
    if (reservation.type === 'SQ' && reservation.section.campus != null) {
      acc.sqCampuses[reservation.section.campus] = true
    }
    acc.reservationsByType[reservation.type].push(reservation)
    return acc
  }, Object.assign({}, JSON.parse(JSON.stringify(initialState)), {
    publicId,
    classes,
    reservations,
    expiresAt,
    extensions
  }))

  state = classes.reduce((acc, c) => {
    acc.classesByType[c.type].push(c)
    return acc
  }, state)

  return state
}

const resetCart = () => Object.assign({}, initialState)

export default function (state = initialState, action) {
  const backendExpired = (['ADD_RESERVATION', 'REMOVE_RESERVATION', 'EXTEND_CART', 'PREFLIGHT'].indexOf(action.type) >= 0) &&
    state.publicId != null && state.publicId !== action.data.cart

  if (backendExpired) {
    // Cart has expired, reset the state
    // Continue operation if ADD_RESERVATION or REMOVE_RESERVATION

    console.error('Cart expired by backend', action.type, action.data, state)
    state = resetCart()

    if (action.type !== 'ADD_RESERVATION' && action.type !== 'REMOVE_RESERVATION') {
      return state
    }
  }

  switch (action.type) {
    case 'ADD_RESERVATION': {
      const newReservationPublicId = action.data.reservations.find(r => state.reservationPublicIds[r] == null)
      const newReservationsAdd = state.reservations.concat({
        ...action.data.newReservationData,
        publicId: newReservationPublicId
      })
      return rebuildState(action.data.cart, state.classes, newReservationsAdd, state)
    }

    case 'REMOVE_RESERVATION': {
      const newReservationsRemove = state.reservations.filter(r => !(r.publicId === action.data.reservationPublicId))
      if (newReservationsRemove.length > 0) {
        return rebuildState(action.data.cart, state.classes, newReservationsRemove, state)
      } else {
        // abandon the old cart id so we'll get a new id and expiresAt on the next ADD_RESERVATION
        return rebuildState(null, state.classes, newReservationsRemove, state)
      }
    }

    case 'ADD_CLASS': {
      const newClasses = state.classes.concat(action.data)
      return rebuildState(action.data.cart, newClasses, state.reservations, state)
    }

    case 'REMOVE_CLASS': {
      const newClasses = state.classes.filter(c => c.section.id !== action.data.sectionId)
      return rebuildState(action.data.cart, newClasses, state.reservations, state)
    }

    case 'EXTEND_CART':
      return Object.assign({}, state, {
        expiresAt: Date.now() + timeAfterExtending - skew,
        extensions: state.extensions + 1
      })

    case 'CLEAR_CART':
      return resetCart()

    case 'PREFLIGHT':
      if (action.data.total !== state.total) {
        console.error('The local cart does not match the backend cart total')
        return resetCart()
      }
      return Object.assign({}, state, {
        expiresAt: Date.now() + timeForCheckout - skew
      })
  }

  return state
}

import React from 'react'

import './Banner.scss'

export default function Banner ({ children, message }) {
  return (
    <div className='page-banner'>
      <h1>{message}</h1>
      {children}
    </div>
  )
}

import React, { useState, useEffect, useContext, useRef } from 'react'
import Bottleneck from 'bottleneck/es5'
import { request } from 'graphql-request'
import Banner from '../Banner'
import { Grid, GridCell } from '@rmwc/grid'
import Button from '~/components/Button'
import Select from '~/components/Select'
import useShowLoader from '../Loader'
import { formatDurationForDisplay, dateRange, timeRange, fullDate, compareDatesAndTimes } from '~/helpers/dateAndTime'
import stripTags from '~/helpers/stripTags'
import { connect } from 'react-redux'
import { wrapButtonToggle } from '~/http'
import { HeaderContext } from '../../App'

import './CourseDetailPage.scss'

const courseQuery = `
query ($courseId: Int!) {
  wedOeCourses(courseId: $courseId) {
    title
    description
    hours
    id
    sections {
      id
      availableCapacity
      location
      maxCapacity
      courseSectionNumber
      startDate
      endDate
      startTime
      endTime
      meetingPattern
      notEnrollableReason
      price
      priceDisplay
      tuitionDisplay
      suppliesDisplay
      bookDisplay
      testDisplay
      prerequisite
      scheduleNotes
      isCeEligible
      ceEligibility
    }
  }
}
`

const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 800,
  highWater: 1
})

function CourseDetailPage (props) {
  const { cart, students, match } = props
  const [course, setCourse] = useState(null)
  const [descriptionDetails, setDescriptionDetails] = useState(false)

  const [addButtonDisabled, setAddButtonDisabled] = useState(false)

  const queryParams = new URLSearchParams(props.location.search)
  const deepLinkedSectionId = queryParams.get('section')

  const captureId = match.params.id
  const { setHeaderInfo } = useContext(HeaderContext)

  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedSectionId, setSelectedSectionId] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)

  const startDateRef = useRef()

  useEffect(() => {
    if (!selectedSectionId && startDateRef.current) {
      startDateRef.current.setValue(null)
    }
    if (selectedSectionId && selectedLocation) {
      const sections = course ? course.sections : []
      const matchingSection = sections.find(s => s.location === selectedLocation && s.id === selectedSectionId)
      if (matchingSection) {
        setSelectedSection(matchingSection)
      }
    }
  }, [selectedLocation, selectedSectionId])

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Class Detail Page' })

    ignoreRateLimiting(async () => {
      try {
        const { wedOeCourses } = await request('/api/public/graphql', courseQuery, { courseId: captureId })
        if (wedOeCourses.length === 1) {
          const newCourse = wedOeCourses[0]
          setCourse(newCourse)
          setDescriptionDetails(calculateDescription(wedOeCourses[0].description))

          const deepLinkedSection = deepLinkedSectionId ? newCourse.sections.find(x => x.id.toString() === deepLinkedSectionId) : null
          const allLocationsAreSame = newCourse.sections.length > 0 &&
            newCourse.sections.every(x => x.location === newCourse.sections[0].location)
          if (deepLinkedSection) {
            updateLocation(deepLinkedSection.location, newCourse)
            setSelectedSectionId(deepLinkedSection.id)
          } else if (newCourse.sections.length === 1) {
            updateLocation(newCourse.sections[0].location, newCourse)
            setSelectedSectionId(newCourse.sections[0].id)
          } else if (allLocationsAreSame) {
            updateLocation(newCourse.sections[0].location, newCourse)
          }
        }
      } catch (err) {
        // FIXME: eat error for now
      }
    })
  }, [])

  const sections = course ? course.sections : []
  const allSectionsHaveSameScheduleNotes = sections.length > 0 && sections[0].scheduleNotes &&
    course.sections.every(x => x.scheduleNotes && x.scheduleNotes.toLowerCase() === course.sections[0].scheduleNotes.toLowerCase())

  const locationOptions = [...new Set(sections.map(section => section.location))].map(location => { return { label: location, value: location } })

  const showLoader = useShowLoader()

  const [dateOptions, setDateOptions] = useState([...new Set(sections.map(s => dateRange(s.startDate, s.endDate)))])
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false)

  const cartContentsBySection = cart.classesByType.WEDOE.reduce((acc, r) => {
    acc[r.section.id] = r
    return acc
  }, {})

  function updateLocation (location, courseOverride = null) {
    if (selectedSection) {
      setSelectedSection(null)
      setSelectedSectionId(null)
    }

    const localCourse = courseOverride ?? course
    const sections = localCourse ? localCourse.sections : []
    const validDatesAcc = sections
      .filter(s => s.location === location)
      .sort((a, b) => compareDatesAndTimes(a, b))
      .reduce((acc, s) => {
        const label = formatDateAndTime(s.startDate, s.startTime)
        if (!acc[label]) {
          acc[label] = []
        }
        acc[label].push({ label, section: s })
        return acc
      }, {})
    const validDates = Object.entries(validDatesAcc)
      .flatMap(x => {
        const sectionGroup = x[1]
        if (sectionGroup.length > 1) {
          return sectionGroup.map(y => { return { label: y.label + ` (${y.section.courseSectionNumber})`, value: y.section.id } })
        }
        return sectionGroup.map(y => { return { label: y.label, value: y.section.id } })
      })

    if (selectedSectionId && !validDates.find(x => x.value === selectedSectionId)) {
      setSelectedSectionId(null)
    }
    validDates.unshift({ label: '', value: '' })
    setDateOptions(validDates)

    setSelectedLocation(location)

    if (selectedLocation.length > 0 && location !== selectedLocation) {
      setSelectedSectionId(null)
      setSelectedSection('')
    }
  }

  function formatDateAndTime (date, time) {
    return `${fullDate(date)}${time ? ' - ' + formatDurationForDisplay(time) : ''}`
  }

  const capacityDisplay = selectedSection
    ? `${selectedSection.availableCapacity} seat${selectedSection.availableCapacity > 1 ? 's' : ''} open`
    : '0 seats open'
  const notEnrollableReason = selectedSection
    ? selectedSection.notEnrollableReason
    : null
  const enrollable = notEnrollableReason == null
  const isFull = selectedSection
    ? selectedSection.availableCapacity <= 0
    : false

  return (
    course
      ? (
        <div className='course-detail-page'>
          <Banner message={course.title} />

          <div className='course-detail-page-sections'>
            {props.history.length > 1 &&
              <Button
                className='backButton'
                label='Back to Previous Page'
                onClick={() => {
                  window.scrollTo(0, 0)
                  props.history.goBack()
                }}
              />}
            <Grid>
              <GridCell desktop={8} tablet={12}>
                <h2>{course.title}</h2>
                <p className='description'>
                  {!descriptionDetails.isExpanded &&
                    <>
                      {descriptionDetails.short}
                      &nbsp;
                      {descriptionDetails.needsExpand &&
                        <button
                          className='description-toggle-btn'
                          onClick={() => {
                            setDescriptionDetails({ ...descriptionDetails, isExpanded: true })
                          }}
                        >Show more...
                        </button>}
                    </>}

                  {descriptionDetails.isExpanded &&
                    <>
                      {descriptionDetails.full}
                      &nbsp;
                      {descriptionDetails.needsExpand &&
                        <button
                          className='description-toggle-btn'
                          onClick={() => {
                            setDescriptionDetails({ ...descriptionDetails, isExpanded: false })
                          }}
                        >Show less
                        </button>}
                    </>}
                </p>

                <div>
                  {((selectedSection && selectedSection.scheduleNotes) || allSectionsHaveSameScheduleNotes) &&
                    <div>
                      <h5>Schedule Notes</h5>
                      <p>{allSectionsHaveSameScheduleNotes ? sections[0].scheduleNotes : selectedSection.scheduleNotes}</p>
                    </div>}

                  {
                    selectedSection && selectedSection.isCeEligible && (
                      <div className='item row selected-section'>
                      This class is eligible for continuing education credit.
                      </div>
                    )
                  }
                </div>
              </GridCell>

              <GridCell desktop={4} tablet={12} className='section-select-form'>
                <div>
                  <h4>Select location and date/time to continue:</h4>

                  <Select
                    label='Location'
                    required
                    options={locationOptions}
                    setter={updateLocation}
                    placeholder={selectedLocation || 'Location'}
                  />

                  <Select
                    disabled={selectedLocation === ''}
                    label='Start Date'
                    required
                    foundationRef={startDateRef}
                    options={dateOptions}
                    setter={setSelectedSectionId}
                    value={selectedSection}
                    placeholder={selectedSection ? formatDateAndTime(selectedSection.startDate, selectedSection.startTime) : 'Start Date'}
                  />
                </div>

              </GridCell>

              {selectedSection &&
                <>
                  <GridCell desktop={4} tablet={12} className='section-details-table'>
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan='2'>
                            <h4>Details</h4>
                          </th>
                        </tr>
                        <tr>
                          <th>Location</th>
                          <td>{selectedSection.location}</td>
                        </tr>
                        <tr>
                          <th>Class Section Number</th>
                          <td>{selectedSection.courseSectionNumber}</td>
                        </tr>
                        <tr>
                          <th>Dates</th>
                          <td>{dateRange(selectedSection.startDate, selectedSection.endDate)}</td>
                        </tr>
                        <tr>
                          <th>Open Seats</th>
                          <td>{capacityDisplay}</td>
                        </tr>
                        {selectedSection.meetingPattern &&
                          <tr>
                            <th>Schedule</th>
                            <td>{selectedSection.meetingPattern} {selectedSection.startTime != null && timeRange(selectedSection.startTime, selectedSection.endTime)}</td>
                          </tr>}
                        {course.hours &&
                          <tr>
                            <th>Hours</th>
                            <td>{course.hours}</td>
                          </tr>}
                      </tbody>
                    </table>
                  </GridCell>
                  <GridCell desktop={4} tablet={12} className='section-details-table'>
                    <table>
                      <tbody>
                        <tr>
                          <th colSpan='2'>
                            <h4>Costs</h4>
                          </th>
                        </tr>
                        <tr>
                          <th>Tuition</th>
                          <td>{selectedSection.tuitionDisplay}</td>
                        </tr>
                        <tr>
                          <th>Supplies</th>
                          <td>{selectedSection.suppliesDisplay}</td>
                        </tr>
                        <tr>
                          <th>Books</th>
                          <td>{selectedSection.bookDisplay}</td>
                        </tr>
                        <tr>
                          <th>Tests</th>
                          <td>{selectedSection.testDisplay}</td>
                        </tr>
                        <tr>
                          <th>Total Cost</th>
                          <td>{selectedSection.priceDisplay}</td>
                        </tr>
                      </tbody>
                    </table>
                  </GridCell>

                </>}

              {selectedSection &&
                <GridCell desktop={4} tablet={12}>
                  {
                    !enrollable && (
                      <div className='not-enrollable-alert'>
                        <b>{notEnrollableReason}</b>
                        <div>
                            Please give us a call at 405-717-4900 between the hours of 8:00 AM and 4:30 PM and we'll assist you.
                        </div>
                      </div>
                    )
                  }

                  {enrollable &&
                    <div>
                      <div>
                        <h4>
                            Add to Cart
                        </h4>
                        {(() => {
                          const classInCart = cartContentsBySection[selectedSection.id]
                          const assignedStudents = !classInCart ? [] : students
                            .filter(student => cart.reservations.some(res => res.section.id === classInCart.section.id && res.studentPublicId === student.publicId))

                          const getAction = function () {
                            if (classInCart != null) {
                              return (
                                <>
                                  <Button
                                    icon='shopping_cart'
                                    label='Checkout'
                                    outlined
                                    dense
                                    onClick={() => {
                                      window.scrollTo(0, 0)
                                      props.history.push('/cart')
                                    }}
                                  />
                                  {assignedStudents.length <= 0 &&
                                    <Button
                                      icon='delete'
                                      label='Remove'
                                      outlined
                                      dense
                                      disabled={removeButtonDisabled}
                                      onClick={
                                        wrapButtonToggle(
                                          setRemoveButtonDisabled,
                                          async () => {
                                            showLoader(true)
                                            await removeFromCart(props, classInCart)
                                            showLoader(false)
                                          })
                                      }
                                    />}
                                  {assignedStudents.length > 0 &&
                                    <p>Cannot remove class from cart while students are assigned to it. Go to the checkout page and remove all students from this class before removing it from your cart.</p>}
                                </>
                              )
                            } else if (isFull) {
                              return <span>Class is Full</span>
                            } else {
                              return (
                                <Button
                                  label='Add to Cart'
                                  unelevated
                                  dense
                                  disabled={addButtonDisabled}
                                  onClick={
                                    wrapButtonToggle(
                                      setAddButtonDisabled,
                                      async () => {
                                        showLoader(true)
                                        await addToCart(props, selectedSection, course)
                                        showLoader(false)
                                      })
                                  }
                                />
                              )
                            }
                          }

                          return (
                            <div className='class-actions'>
                              {getAction()}
                            </div>
                          )
                        })()}
                      </div>
                    </div>}
                </GridCell>}
            </Grid>
          </div>
        </div>)
      : null
  )
}

async function ignoreRateLimiting (fn) {
  try {
    await limiter.schedule(() => fn())
  } catch (err) {
    if (!(err instanceof Bottleneck.BottleneckError)) {
      // Ignore the intentional throttling rejections
      throw err
    }
  }
}

async function removeFromCart (props, reservation) {
  props.dispatch({
    type: 'REMOVE_CLASS',
    data: {
      sectionId: reservation.section.id
    }
  })
}

async function addToCart (props, section, course) {
  props.dispatch({
    type: 'ADD_CLASS',
    data: {
      cart: props.cart.publicId,
      course: course,
      section: section,
      type: 'WEDOE'
    }
  })
}

function calculateDescription (description, minimumCharLength = 500) {
  description = description.trim()

  if (description[0] === '"') description = description.slice(1)
  if (description[description.length - 1] === '"') description = description.slice(0, description.length - 1)

  const words = stripTags(description).split(' ').filter(w => w.length > 0)
  const alwaysVisible = words.reduce((acc, word, i) => {
    const lastWord = acc.words[acc.words.length - 1]
    if (acc.length > minimumCharLength && lastWord[lastWord.length - 1] === '.') {
      return acc
    }
    acc.words.push(word)
    acc.length += word.length
    return acc
  }, { words: [], length: 0 })

  return {
    short: alwaysVisible.words.join(' '),
    full: words.join(' '),
    needsExpand: alwaysVisible.words.length < words.length,
    isExpanded: false
  }
}

export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(CourseDetailPage)

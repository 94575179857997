import React from 'react'
import { default as ReactSelect } from 'react-select' // eslint-disable-line

import '@rmwc/select/styles'
import './Select.scss'

// TODO: Add css to have the label stick around and move up when the select has value
export default function Select (props) {
  const {
    className = '',
    name = '',
    label,
    required = false,
    fieldErrors = {},
    options,
    onChange,
    disabled,
    foundationRef,
    setter,
    placeholder,
    value,
    clearOnSelect = false
  } = props
  const id = name.replace(/[^a-zA-Z0-9]/g, '-')
  const cleanName = name.replace(/\s*\*$/, '')

  const selectStyles = {
    control: styles => ({
      ...styles,
      padding: '0.75em',
      fontSize: '0.70em',
      fontFamily: 'Roboto',
      border: '1px solid #A0A0A0'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontWeight: 350,
        fontSize: '0.75em',
        fontFamily: 'Roboto',
        cursor: isDisabled ? 'not-allowed' : 'default'
      }
    }
  }

  const selectWithValueAlwaysCleared =
    <ReactSelect
      id={id}
      disabled={disabled}
      invalid={fieldErrors[cleanName] != null}
      className={`nselect ${className}`}
      label={`${label != null ? label : name}`}
      required={required}
      options={options}
      foundationRef={foundationRef}
      outlined
      onChange={setter != null
        ? evt => { setter(evt.value) }
        : onChange}
      enhanced
      styles={selectStyles}
      value=''
      placeholder={<div>{`${placeholder || label}`}</div>}
    />

  const defaultSelect =
    <ReactSelect
      id={id}
      disabled={disabled}
      invalid={fieldErrors[cleanName] != null}
      className={`nselect ${className}`}
      label={`${label != null ? label : name}`}
      required={required}
      options={options}
      foundationRef={foundationRef}
      outlined
      onChange={setter != null
        ? evt => { setter(evt.value) }
        : onChange}
      enhanced
      styles={selectStyles}
      placeholder={<div>{`${placeholder || label}`}</div>}
      value={options.find(option => option.value === value)}
    />

  const select = clearOnSelect ? selectWithValueAlwaysCleared : defaultSelect

  return select
}

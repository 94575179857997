import React, { useState, useEffect, useContext, useRef } from 'react'
import { HeaderContext } from '../App'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Checkbox from './Checkbox'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import Paypal from '~/components/Paypal'
import Banner from '~/components/Banner'
import Select from '~/components/Select'
import List, { SimpleListItem } from '~/components/List'
import TextField from '~/components/TextField'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog'
import recaptcha from '~/helpers/loadRecaptcha'
import { useWeeks, useTimeSlots } from '~/refdata'
import { formatDurationForDisplay, dateRange, timeRange, fullDate, terseDate, durationToMoment } from '~/helpers/dateAndTime'
import http, { preparePayload, wrapButtonToggle, formatErrors } from '~/http'

import useShowLoader from './Loader'
import StudentCard from './StudentCard'

// --Components
function CartPage (props) {
  const { cart, students } = props
  const [globalError, setGlobalError] = useState(null)
  const [paypalButtonDisabled, setPaypalButtonDisabled] = useState(false)
  const [paypalButtonLoaded, setPaypalButtonLoaded] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [captureId, setCaptureId] = useState(null)
  const [mailPermission, setMailPermission] = useState(true)
  const [educationLevelOptions, setEducationLevelOptions] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  const [ceInfo, setCeInfo] = useState(null)
  const showLoader = useShowLoader()
  const sqWeeks = useWeeks()
  const sqTimeslots = useTimeSlots()

  const mailPermissionRef = useRef(mailPermission)

  const { setHeaderInfo } = useContext(HeaderContext)

  const getPersonOptionData = async () => {
    const response = await http(setGlobalError, http => http.get('/api/SelfService/Students/GetStudentOptionData'))
    const educationLevels = response.data.educationLevels.map(x => {
      return { label: x.name, value: x.id }
    })
    const languages = response.data.languages.map(x => {
      return { label: x.name, value: x.id }
    })

    setLanguageOptions(languages)
    setEducationLevelOptions(educationLevels)
  }

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'My Cart' })
    getPersonOptionData()
  }, [])

  useEffect(() => {
    mailPermissionRef.current = mailPermission
  }, [mailPermission])

  if (captureId != null) {
    props.history.push(`/receipt/${captureId}`)
    window.scrollTo(0, 0)
    props.dispatch({
      type: 'RESET_STATE',
      data: {}
    })
  }

  const showLocationsWarning = cart.classesByType.SQ.length > 0 && Object.keys(cart.sqCampuses).length > 1

  const cartContentsBySection = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.section.id} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const cartContentsByCourse = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.courseId} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const sectionPrerequisites = cart.classesByType.WEDOE
    .filter(reservation => reservation.section.prerequisite != null)
    .reduce((acc, res) => {
      const { section, course } = res
      const title = course.title.trim()
      if (acc[title] == null) {
        acc[title] = { title, prerequisite: section.prerequisite }
      }
      return acc
    }, {})

  const sectionCeEligibility = cart.classesByType.WEDOE
    .filter(reservation => reservation.section.isCeEligible)
    .reduce((acc, res) => {
      const { course } = res
      const title = course.title.trim()
      if (acc[title] == null) {
        acc[title] = { title }
      }
      return acc
    }, {})

  const readyToPay = cart.classes.filter(x => !cart.reservations.find(reservation => reservation.section.id === x.section.id)).length === 0

  return (
    <div className='page cart'>
      <Banner message='Cart' />

      <CeLicenseDialog {...{ parentProps: props, setGlobalError, showLoader, ceInfo, setCeInfo, setPaypalButtonDisabled }} />

      {overlay && (
        <>
          <div className='page-header'>
            <div className='contents row'>
              Processing
            </div>
          </div>

          <div className='page-body'>
            <div className='contents'>
              <ErrorMessage notice message='Please wait while we process your order...' />
            </div>
          </div>
        </>
      )}

      <div className='page-body extended' style={{ display: overlay ? 'none' : undefined }}>
        {globalError && <ErrorMessage message={globalError} />}
        {showLocationsWarning && <ErrorMessage warning message='Warning: Your cart contains classes at multiple locations' />}

        <div className='empty-cart row'>
          {cart.classes.length === 0 &&
            <>
              <div>Your cart is empty, let's start down a new path to learning.</div>
              <div style={{ display: 'flex', gap: '8px' }}>
                <Button
                  label='Classes'
                  icon='school'
                  unelevated
                  onClick={() => props.history.push('/ll-course-categories')}
                />
                <Button
                  label='Summer Quest'
                  icon='sunny'
                  unelevated
                  onClick={() => props.history.push('/sq-courses')}
                />
              </div>
            </>}
        </div>

        {cart.classes.length > 0 &&
          <div>
            <h3>Add Your Student Information</h3>
            {students.length <= 0 && <p>You must add student information to continue.</p>}
            {students.map((student, index) => {
              return (
                <StudentCard
                  key={`student-${student.publicId}-${student.firstName}-${student.lastName}`}
                  student={student}
                  removeStudent={() => removeStudent(props, setGlobalError, student, index)}
                  languageOptions={languageOptions}
                  educationLevelOptions={educationLevelOptions}
                />
              )
            })}

            <Button
              label='Add Student'
              icon='person_add'
              unelevated
              onClick={() => gotoAddStudent(props)}
            />
          </div>}

        {cart.classes.length > 0 && students.length > 0 &&
          <>
            <div className='class-card-list'>
              <h3>Review Your Classes</h3>
              {cart.classes.map((c, index) => {
                const assignedStudents = students
                  .filter(student => cart.reservations.some(res => res.section.id === c.section.id && res.studentPublicId === student.publicId))
                const possibleStudentsToAssign = students
                  .filter(student => !assignedStudents.includes(student))
                  .map(student => { return { label: `${student.firstName} ${student.lastName}`, value: student.publicId } })

                const week = c.type === 'SQ' ? sqWeeks.find(x => x.id === c.section.weekId) : null
                const timeSlot = c.type === 'SQ' ? sqTimeslots.find(x => x.id === c.section.timeSlotId) : null

                const studentCourseDuplicates = (selectedSectionId) =>
                  students.filter((student) => {
                    const inCourse = cartContentsByCourse[`${c.course.id} ${student.publicId}`] != null
                    const inSection = cartContentsBySection[`${selectedSectionId} ${student.publicId}`] != null
                    return inCourse && !inSection
                  }
                  )

                const showLocationWarning = c.type === 'SQ' && c.section != null &&
                  cart.reservationsByType.SQ.length > 0 &&
                  c.section.campus != null &&
                  cart.sqCampuses[c.section.campus] == null

                return (
                  <div className='class-card card mdc-card' key={index}>
                    <div>
                      <div className='class-card-title'>
                        <h3>
                          {c.type === 'SQ' &&
                            <a href={`/#/sq-courses?q=${c.course.courseName}`}>
                              {c.course.courseName}
                            </a>}
                          {c.type !== 'SQ' &&
                            <a href={`/#/ll-courses/${c.course.id}?section=${c.section.id}`}>
                              {c.course.title}
                            </a>}
                        </h3>
                        {assignedStudents.length <= 0 &&
                          <Button
                            icon='delete'
                            label='Remove'
                            outlined
                            dense
                            onClick={() => {
                              (async () => {
                                showLoader(true)
                                await removeClassFromCart(props, c)
                                showLoader(false)
                              })()
                            }}
                          />}
                      </div>
                      {c.type !== 'SQ' &&
                        <>
                          <sub>{c.section.location} - {dateRange(c.section.startDate, c.section.endDate)}</sub>
                          <br />
                          <sub>{c.section.meetingPattern} {c.section.startTime != null && timeRange(c.section.startTime, c.section.endTime)}</sub>
                        </>}
                      {c.type === 'SQ' && week && timeSlot &&
                        <>
                          <sub>Summer Quest {`${week.name}, ${terseDate(week.startDate)}-${terseDate(week.endDate)}`}</sub>
                          <br />
                          <sub>{c.section.campus} {`${durationToMoment(timeSlot.startTime).format('h:mm')}-${durationToMoment(timeSlot.endTime).format('h:mm A')}`}</sub>
                          {studentCourseDuplicates(c.section.id).map((student) =>
                            <ErrorMessage key={`dup-${c.section.id}-${student.publicId}`} warning message={`Warning: Your cart already contains an enrollment in this class for ${student.firstName} ${student.lastName} `} />
                          )}
                          {showLocationWarning &&
                            <ErrorMessage key={`location-warninr-${c.section.id}`} warning message='Warning: This class is located at a different campus from other classes in your cart' />}
                        </>}

                    </div>
                    <div>
                      <br />
                      {possibleStudentsToAssign.length === 0 &&
                        <p>
                      All students have been assigned to this class already.
                        </p>}
                      <Select
                        className='width-100-percent student-select'
                        label='Assign a student'
                        clearOnSelect
                        disabled={possibleStudentsToAssign.length === 0}
                        options={students
                          .filter(student => !cart.reservations.some(res => res.section.id === c.section.id && res.studentPublicId === student.publicId))
                          .map(student => { return { label: `${student.firstName} ${student.lastName}`, value: student.publicId } })}
                        setter={async studentPublicId => {
                          const student = students.find((x) => x.publicId === studentPublicId)
                          if (c.section.isCeEligible) {
                            setCeInfo({ ceEligibility: c.section.ceEligibility, student, selectedSection: c.section, course: c.course, courseType: c.type })
                          } else {
                            setPaypalButtonDisabled(true)
                            showLoader(true)
                            await addToCart(props, setGlobalError, student, c.section, c.course, c.type)
                            showLoader(false)
                            setPaypalButtonDisabled(false)
                          }
                        }}
                      />
                      {assignedStudents.length > 0 &&
                        <>
                          <h4>Assigned Students</h4>
                          <List>
                            {cart.reservations.filter(x => x.section.id === c.section.id).map((reservation, i) => {
                              const student = students.find(x => x.publicId === reservation.studentPublicId)
                              return (
                                <SimpleListItem
                                  key={i}
                                  text={`${student.firstName} ${student.lastName}`}
                                  graphic='person'
                                  meta={
                                    <Button
                                      label='Remove'
                                      icon='person_remove'
                                      onClick={
                                        wrapButtonToggle(
                                          setPaypalButtonDisabled,
                                          async () => {
                                            showLoader(true)
                                            await removeFromCart(props, setGlobalError, reservation, student)
                                            showLoader(false)
                                          })
                                      }
                                    />
                                  }
                                />
                              )
                            })}
                          </List>
                        </>}
                    </div>

                  </div>
                )
              })}

            </div>

            {readyToPay &&
              <>
                <div className='total'>{`Total: $${cart.total.toFixed(2)}`}</div>
                <div className='row mail-permission'>
                  <Checkbox
                    nativeControlId='mail-permission'
                    checked={mailPermission}
                    onChange={(evt) => {
                      setMailPermission(evt.target.checked)
                    }}
                    label={'I\'d like to stay connected with Francis Tuttle via email'}
                  />
                </div>
                <div className='discounts'>
                Discounts may be available for people in the following categories: Military/Veteran, FT Board, FT Foundation Board, Employee, Employee Family, Retired Employee, Senior Citizen, Industry Advisor, and Sending School Partner.
                If you fit into one of these, please contact our enrollment offices at 405-717-4900 to process your enrollment.
                </div>
                {
                  Object.keys(sectionPrerequisites).length > 0 && (
                    <div className='prerequisites'>
                      The Classes below contain pre-requisites, some of which are mandated by governing agencies.
                      Please make sure you meet these requirements.
                      If you do enroll, there is a chance you could be removed from the class for not meeting governed mandatory requirements.
                      In this case, your money will be refunded through our normal refund process, which can take up to 10 business days.
                      If you have further questions regarding pre-requisites for this class please call 405-717-4900
                      {
                        Object.keys(sectionPrerequisites)
                          .map((key, idx) => {
                            const { title, prerequisite } = sectionPrerequisites[key]
                            return <div key={idx}>{title} ({prerequisite})</div>
                          })
                      }
                    </div>
                  )
                }
                {
                  Object.keys(sectionCeEligibility).length > 0 && (
                    <div className='continuing-ed'>
                      The Classes below are eligible for continuing education credit.
                      A license is not required to take them.
                      However, failure to provide your information could result in a delay in, or not receiving credit.
                      {
                        Object.keys(sectionCeEligibility)
                          .map((key, idx) => {
                            const { title } = sectionCeEligibility[key]
                            return <div key={idx}>{title}</div>
                          })
                      }
                    </div>
                  )
                }
                <div className='legal-disclosures'>
                  <p>
                  Students must be 16 years old or older in order to enroll in the majority of the classes. There are exceptions, however, for some classes, such as ACT Prep. To check if a class has an exception to the age requirement, please contact our enrollment offices at 405-717-4900.
                  </p>
                  <p>
                  Refund policy: "Payment is due at the time of enrollment. If you let us know you will be unable to attend, more than three business days before the class starts, we will be happy to provide a refund for the cost of tuition, books, and tests; however, any supplies costs are non-refundable.
                  </p>
                  <p>
                  Please note, if you cancel an enrollment within three business days of the first day of the class, no refund will be provided. We will be happy, though, to assist you in transferring to another class. This must occur at the time of cancellation."
                  </p>
                </div>
                <div className='terms-acknowledge'>
                  By clicking any of the payment options below, I agree to the <Link to='/terms'>Terms of Use</Link>.
                </div>
              </>}
            {!readyToPay && cart.classes && cart.classes.length > 0 &&
              <p>You must assign your student(s) to your classes before you can start the payment process.</p>}
            <div className='payment-placeholder'>
              {!paypalButtonLoaded && globalError == null && readyToPay && <span id='paypal-loading'>Loading PayPal, please wait...</span>}

              {!paypalButtonDisabled && readyToPay && (
                <Paypal
                  globalError={globalError}
                  setGlobalError={setGlobalError}
                  setPaypalButtonDisabled={setPaypalButtonDisabled}
                  setPaypalButtonLoaded={setPaypalButtonLoaded}
                  mailPermissionRef={mailPermissionRef}
                  setOverlay={setOverlay}
                  setCaptureId={setCaptureId}
                />
              )}
            </div>
          </>}
      </div>

    </div>
  )
}

function CeLicenseDialog (props) {
  const { parentProps, setGlobalError, showLoader, ceInfo, setCeInfo, setPaypalButtonDisabled } = props

  const defaultForm = {}
  const [formValues, setFormValues] = useState(defaultForm)
  const [fieldErrors, setFieldErrors] = useState([])
  const [localError, setLocalError] = useState(undefined)

  let isCleet, isInsurance

  useEffect(() => {
    if (ceInfo) {
      isCleet = ceInfo.ceEligibility.includes('CLEET')
      isInsurance = ceInfo.ceEligibility.filter(type => type.indexOf('Insurance') !== -1).length > 0
    }
  }, [ceInfo])

  async function captureCeLicense () {
    let token = null
    try {
      token = await recaptcha('captureCeLicense')
    } catch {
      // silently fail on recaptcha error
      return
    }

    const ceLicenses = ceInfo.ceEligibility.reduce((acc, type) => {
      if (formValues[type] != null && formValues[type] !== '') {
        acc.push({ type, number: formValues[type] })
      }
      return acc
    }, [])

    const tempPayload = {
      recaptchaToken: token,
      ceLicenses
    }

    if (formValues.SSN != null && formValues !== '') {
      tempPayload.ssn = formValues.SSN
    }

    if (formValues.npn != null && formValues !== '') {
      tempPayload.npn = formValues.npn
    }

    const payload = preparePayload(tempPayload)

    try {
      setPaypalButtonDisabled(true)
      showLoader(true)
      if (await addToCart(parentProps, setGlobalError, ceInfo.student, ceInfo.selectedSection, ceInfo.course, ceInfo.courseType)) {
        await http(setGlobalError, http => http.post(`/api/SelfService/Students/${ceInfo.student.publicId}/AddCeInfo`, payload))
      }
    } catch (err) {
      if (err.response != null && err.response.status === 400) {
        setLocalError('Please fix the errors on the page and try again.')
        setFieldErrors(formatErrors(err.response.data))
      } else if (err.response != null && err.response.status === 422) {
        setLocalError(err.response.data.message)
        setFieldErrors({})
      } else if (err.response != null && err.response.status === 424) {
        setLocalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll.')
        setFieldErrors({})
      } else {
        setLocalError('Unexpected error: Please refresh the page and try again or call us at 405-717-4900.')
        setFieldErrors({})
      }
    } finally {
      showLoader(false)
      setPaypalButtonDisabled(false)
    }
  }

  return (
    <>
      {ceInfo &&
        <Dialog
          open={ceInfo}
          onClose={evt => {
            setCeInfo(null)
            setFormValues(defaultForm)
          }}
          className='full-page'
        >
          <DialogTitle>Continuing Education Credit</DialogTitle>
          {
            localError !== undefined && <ErrorMessage message={localError} />
          }
          <DialogContent>
            <p>
              The class you’ve selected for enrollment is eligible for continuing education credit for {ceLicenseTypeDisplay(ceInfo.ceEligibility)}.
              A license is not required to take the class.
              However, if you are taking the class to meet CE requirements, please provide your license type, license number and/or Social Security number, as required by the governing agency.
              Failure to provide your information could result in a delay in, or not receiving credit for the class.
              If you have further questions, please call 405-717-4900.
            </p>
            <form name='ce-license' action='POST' method='/' onSubmit={(e) => e.preventDefault()}>
              {
                ceInfo.ceEligibility.map((type, idx) => {
                  return (
                    <CeLicenseNumberField
                      {...{ type, formValues, setFormValues, fieldErrors }}
                      key={idx}
                    />
                  )
                })
              }
              {
                isCleet && (
                  <>
                    <CeLicenseNumberField
                      {...{ formValues, setFormValues, fieldErrors }}
                      type='SSN'
                    />
                    <span>SSN is required for CLEET CE credit</span>
                  </>
                )
              }
              {
                isInsurance && (
                  <>
                    <CeLicenseNumberField
                      {...{ formValues, setFormValues, fieldErrors }}
                      type='npn'
                    />
                    <span>NPN is required for Insurance CE credit</span>
                  </>
                )
              }
            </form>
          </DialogContent>
          <DialogActions>
            <DialogButton action='close'>Cancel</DialogButton>
            <DialogButton
              action='accept'
              onClick={async () => {
                setPaypalButtonDisabled(true)
                showLoader(true)
                await addToCart(parentProps, setGlobalError, ceInfo.student, ceInfo.selectedSection, ceInfo.course, ceInfo.courseType)
                showLoader(false)
                setPaypalButtonDisabled(false)
              }}
              isDefaultAction
            >
            Not taking continuing education
            </DialogButton>
            <DialogButton onClick={() => captureCeLicense()}>
            Add my license and enroll
            </DialogButton>
          </DialogActions>
        </Dialog>}
    </>
  )
}

function CeLicenseNumberField ({ type, formValues, setFormValues, fieldErrors }) {
  const label = !['SSN', 'npn'].includes(type)
    ? `${propDisplayString(type)} License Number`
    : type === 'SSN'
      ? 'SSN (no hyphens)'
      : type.toUpperCase()
  return (
    <TextField
      className='full-width'
      label={label}
      value={formValues[type]}
      onChange={(evt) => {
        const values = { ...formValues }
        values[type] = evt.target.value
        setFormValues({ ...values })
      }}
    />
  )
}

// --functions
function propDisplayString (propName) {
  return propName.replace(/([a-z0-9])([A-Z])/g, '$1 $2') // TODO: de-dup this
}

function ceLicenseTypeDisplay (ceEligibility) {
  return propDisplayString(ceEligibility
    .reduce((acc, type, idx) => {
      if (idx === ceEligibility.length - 1) {
        acc += type
      } else if (idx === ceEligibility.length - 2) {
        acc += type + ' and '
      } else {
        acc += type + ', '
      }
      return acc
    }, ''))
}

async function addToCart (props, setGlobalError, student, section, course, courseType) {
  let token = null
  try {
    token = await recaptcha('addToCart')
  } catch {
    // silently fail on recaptcha error
    return
  }

  const payload = {
    recaptchaToken: token,
    cartPublicId: props.cart.publicId,
    studentPublicId: student.publicId,
    sectionId: section.id
  }

  try {
    const endpoint = courseType === 'SQ'
      ? '/api/SelfService/Cart/AddEnrollment'
      : '/api/self-service/cart/wed/oe/add'
    const response = await http(setGlobalError, http => http.post(endpoint, payload))

    const startTime = section.startTime != null
      ? `${formatDurationForDisplay(section.startTime)}-${formatDurationForDisplay(section.endTime)}`
      : 'Online'
    const ageGroup = courseType === 'SQ'
      ? section.ageGroup
      : 'Adult'

    props.dispatch({
      type: 'ADD_RESERVATION',
      data: {
        cart: response.data.cart,
        reservations: response.data.reservations,
        newReservationData: {
          courseId: course.id,
          courseName: courseType === 'SQ' ? course.courseName : course.title,
          section: {
            id: section.id,
            ageGroup,
            availableCapacity: section.availableCapacity,
            campus: courseType === 'SQ' ? section.campus : section.location,
            courseSectionNumber: section.courseSectionNumber,
            price: section.price,
            notEnrollableReason: section.notEnrollableReason,
            startDate: `${fullDate(section.startDate)}-${fullDate(section.endDate)}`,
            startTime,
            prerequisite: section.prerequisite,
            isCeEligible: section.isCeEligible
          },
          studentName: `${student.firstName} ${student.lastName}`,
          studentPublicId: student.publicId,
          type: courseType
        }
      }
    })
    return true
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 409) {
      setGlobalError('This student already has a reservation in this class.')
    } else if (err.response != null && err.response.status === 422) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 424) {
      setGlobalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll into.')
    }
    window.scrollTo(0, 0)
    return false
  }
}

async function removeClassFromCart (props, reservation) {
  props.dispatch({
    type: 'REMOVE_CLASS',
    data: {
      cart: props.cart.publicId,
      sectionId: reservation.section.id
    }
  })
}

async function removeFromCart (props, setGlobalError, reservation, student) {
  const payload = {
    cartPublicId: props.cart.publicId,
    reservationPublicId: reservation.publicId,
    studentPublicId: student.publicId
  }

  const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/RemoveEnrollment', payload))
  props.dispatch({
    type: 'REMOVE_RESERVATION',
    data: {
      cart: response.data.cart,
      reservationPublicId: reservation.publicId
    }
  })
}

function gotoAddStudent (props) {
  props.history.push('/addstudent?cart=true')
}

function removeStudent (props, setGlobalError, student, index) {
  if (props.cart.students[student.publicId]) {
    setGlobalError('This student cannot be deleted because your cart contains enrollments for them')
    window.scrollTo(0, 0)
    return
  }
  props.dispatch({
    type: 'REMOVE_STUDENT',
    data: index
  })
  setGlobalError(null)
}

// --mappings
export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(CartPage)

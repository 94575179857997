import React, { useEffect, useContext } from 'react'
import { HeaderContext, defaultPageTitle } from '../../App'
import Button from '~/components/Button'

// pass header info in via context'

import '../../scss/SqLanding.scss'

export default function WelcomePage (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: defaultPageTitle })
  }, [])

  return (
    <div className='page welcome'>

      <div className='page-header'>
        <div className='contents'>
          <div className='title'>
            <div>Class Enrollment Page</div>
          </div>

          <div className='paragraph'>
              Our classes offer you various opportunities to improve your career and expand your horizons. Our full list of classes includes the many ways you can advance as a professional, continuing the education you need to move up and take on the next challenge.
          </div>

          <div className='paragraph'>
            There are also numerous classes that teach skills like cooking, crafting and painting, and introduce ways to enjoy various hobbies that provide personal enrichment. Search for yourself and come see our experienced instructors to begin a fun-filled learning experience!
          </div>

          <div className='button-container'>
            <Button
              unelevated
              label='Enroll a student'
              onClick={() => {
                window.scrollTo(0, 0)
                props.history.push('/students')
              }}
            />
            <Button
              outlined
              label='View Classes'
              onClick={() => {
                window.scrollTo(0, 0)
                props.history.push('/ll-course-categories')
              }}
            />
          </div>
        </div>
      </div>

      <div className='page-body'>
        <div className='paragraph'>
            Students must be 16 years old or older in order to enroll in the majority of the classes.
            There are exceptions, however, for some classes, such as ACT Prep.
            To check if a class has an exception to the age requirement, please contact our enrollment offices at 405-717-4900.
        </div>
        <div className='paragraph'>
            Refund policy: "Payment is due at the time of enrollment. If you let us know you will be unable to attend, more than three business days before the class starts, we will be happy to provide a refund for the cost of tuition, books, and tests; however, any supplies costs are non-refundable.
        </div>
        <div className='paragraph'>
Please note, if you cancel an enrollment within three business days of the first day of the class, no refund will be provided. We will be happy, though, to assist you in transferring to another class. This must occur at the time of cancellation."
        </div>

        <div className='button-container'>
          <Button
            unelevated
            label='Enroll a student'
            onClick={() => {
              window.scrollTo(0, 0)
              props.history.push('/students')
            }}
          />
        </div>

        <div className='notice'>
            This site uses Google reCAPTCHA v3.
        </div>
      </div>

    </div>
  )
}

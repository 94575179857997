import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../App'
import Bottleneck from 'bottleneck/es5'
import qs from 'querystring'
import { Link } from 'react-router-dom'
import { Icon } from '@rmwc/icon'
import { Grid, GridCell } from '@rmwc/grid'
import TextField from '../TextField'
import Loader from '~/components/shared/Loader'
import '@rmwc/grid/styles'

import Banner from '../Banner'
import Button from '~/components/Button'
import { ListItem } from '@rmwc/list'
import '@rmwc/list/styles'
import '@rmwc/tabs/styles'

import { useLocations, useCourseCategories } from '~/refdata'
import { request } from 'graphql-request'

import searchQuery from './search-query'
import './CourseListPage.scss'

// --vars
const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 800,
  highWater: 1
})

// --components
export default function CourseListPage (props) {
  const { history, location } = props
  const locations = useLocations()
  const courseCategories = useCourseCategories()
  const parsedQueryParameters = qs.parse(location.search.slice(1))

  const [pageParameters, setPageParameters] = useState(() => {
    const query = parsedQueryParameters.q === 'undefined' || parsedQueryParameters.q == null ? '' : parsedQueryParameters.q
    const category = parsedQueryParameters.c
    return { query, category }
  })

  const searchText = useDebouncedValue(pageParameters.query, 500)
  const [chosenCategory, setChosenCategory] = useState(null)
  const [categoryHasSettled, setCategoryHasSettled] = useState(false)

  const [courseGroups, setCourseGroups] = useState([])
  const [wedOeCourses, setWedOeCourses] = useState([])
  const [activeCourseLocation, setActiveCourseLocation] = useState(null)
  const [loading, setLoading] = useState(false)
  const [locationsSelected, setLocationsSelected] = useState([])

  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Classes' })
  }, [])

  useEffect(() => {
    if (courseCategories && courseCategories.length > 0) {
      history.push({ search: `?q=${pageParameters.query}&c=${pageParameters.category ? pageParameters.category : ''}` })
    }
  }, [pageParameters])

  useEffect(() => {
    setLocationsSelected(
      locations.map(loc => loc.name)
    )
  }, [locations])

  useEffect(() => {
    const category = courseCategories.find(x => x.name === pageParameters.category)
    setChosenCategory(category)
    setCategoryHasSettled(true)
  }, [courseCategories])

  // NOTE: this is where the query gets fired.
  useEffect(() => {
    // load all available by default
    if (locationsSelected.length === 0) {
      setCourseGroups([])
      setActiveCourseLocation(null)
      setLoading(false)
      return
    }

    if (!categoryHasSettled) return

    setLoading(true)
    ignoreRateLimiting(async () => {
      try {
        const { wedOeCourses } = await request(
          '/api/public/graphql',
          searchQuery,
          { searchText: searchText, locations: locationsSelected, courseCategories: chosenCategory ? [chosenCategory.name] : [] })
        const groups = wedOeCourses.reduce((acc, course) => {
          for (const section of course.sections) {
            if (!acc[section.location]) {
              const sectionCopy = deepClone(section)
              const courseCopy = deepClone(course)
              courseCopy.sections = [sectionCopy]
              acc[section.location] = [courseCopy]
            } else {
              const existingLocationGroup = acc[section.location]
              const existingCourse = existingLocationGroup.find(c => c.id === course.id)
              if (existingCourse) {
                const sectionCopy = deepClone(section)
                existingCourse.sections.push(sectionCopy)
              } else {
                const courseCopy = deepClone(course)
                courseCopy.sections = [deepClone(section)]
                existingLocationGroup.push(courseCopy)
              }
            }
            return acc
          }
        }, {})
        setWedOeCourses(wedOeCourses)
        setCourseGroups(groups)
        setActiveCourseLocation(null)
      } catch (err) {
        // FIXME: eat error for now
      } finally {
        setLoading(false)
      }
    })
  }, [searchText, chosenCategory, locationsSelected, courseCategories])

  return (
    <div className='course-list-page'>
      <Banner message={`${chosenCategory ? chosenCategory.description + ' ' : ''}Classes`} />

      <>
        <div className='course-list-section'>
          <div className='link-group'>
            <Button
              label='Back to Class Categories'
              onClick={() => {
                window.scrollTo(0, 0)
                props.history.push('/ll-course-categories')
              }}
            />
          </div>

          <TextField
            className='course-search-field'
            name='query'
            label={`Search ${chosenCategory ? chosenCategory.description : ''}`}
            value={pageParameters.query}
            onChange={evt => {
              evt.preventDefault()
              setPageParameters({ ...pageParameters, query: evt.target.value.slice(0, 50) })
            }}
          />

          {loading && <Loader />}

          {(!loading && (!courseGroups || Object.keys(courseGroups).length === 0)) &&
            <h2 className='no-classes-warning'>0 classes found</h2>}

          {courseGroups && Object.keys(courseGroups).length > 0 &&
            <>
              <div className='course-list-location-chips'>
                <button
                  className={`custom-chip ${!activeCourseLocation ? 'selected' : ''}`}
                  tabIndex={0}
                  onClick={() => setActiveCourseLocation(null)}
                >
                  <Icon icon={{
                    icon: !activeCourseLocation ? 'check' : 'close',
                    size: 'small'
                  }}
                  />
                  All
                  <span>{Object.values(courseGroups).reduce((sum, group) => group.length + sum, 0)}
                  </span>
                </button>

                {Object.keys(courseGroups).toSorted((a, b) => courseGroups[b].length - courseGroups[a].length).map((location, idx) => {
                  return (
                    <button
                      key={idx}
                      className={`custom-chip ${activeCourseLocation === location ? 'selected' : ''}`}
                      tabIndex={0}
                      onClick={() => setActiveCourseLocation(location)}
                    >
                      <Icon icon={{
                        icon: activeCourseLocation === location ? 'check' : 'close',
                        size: 'small'
                      }}
                      />
                      {location}
                      <span>{courseGroups[location].length}</span>
                    </button>)
                })}
              </div>

              <hr />

              <Grid className='course-list-course-grid'>
                {(!activeCourseLocation ? wedOeCourses : courseGroups[activeCourseLocation]).map((course, idx) =>
                  <GridCell key={idx} span={6}>
                    <ListItem key={course.id} tag={Link} to={`/ll-courses/${course.id}/`}>
                      <Icon icon={{
                        icon: 'keyboard_double_arrow_right',
                        size: 'large'
                      }}
                      />
                      {course.title}
                    </ListItem>
                  </GridCell>
                )}
              </Grid>
            </>}
        </div>
      </>
    </div>
  )
}

// --functions
async function ignoreRateLimiting (fn) {
  try {
    await limiter.schedule(() => fn())
  } catch (err) {
    if (!(err instanceof Bottleneck.BottleneckError)) {
      // Ignore the intentional throttling rejections
      throw err
    }
  }
}

function useDebouncedValue (value, delay) {
  const [state, setState] = useState(value)
  useEffect(() => {
    const timer = setTimeout(setState, delay, value)
    return () => { clearTimeout(timer) }
  }, [value, delay])
  return state
}

function deepClone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

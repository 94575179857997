import React, { useState } from 'react'
import Card from '../components/Card'
import Button from '~/components/Button'
import SVGIcon from '~/components/SVGIcon'
import { ageToday } from '~/helpers/dateAndTime'
import moment from 'moment'

export default function StudentCard (props) {
  const [expanded, setExpanded] = useState(false)

  const { student, removeStudent, languageOptions, educationLevelOptions } = props

  const {
    firstName,
    middleName,
    lastName,
    gender,
    birthDate,
    contacts,
    phoneNumber,
    optOutOfTextMessages,
    email,
    address1,
    address2,
    city,
    state,
    zipCode,
    publicId,
    notes
  } = student
  const sponsorshipInfo = student.companySponsorshipInfo
  const language = languageOptions.find(x => x.value === Number(student.language))?.label
  const educationLevel = educationLevelOptions.find(x => x.value === Number(student.educationLevel))?.label
  const studentIsSponsored = sponsorshipInfo &&
    (sponsorshipInfo.companyGaveTimeOff ||
    sponsorshipInfo.companyIsPaying ||
    sponsorshipInfo.companyWillReimburse)
  const studentIsAdult = ageToday(birthDate) >= 16

  // checking to see if the student or their contacts
  // have Opted out of text messages to show or hide Asterik note on bottom of card
  const hasOptedOutOfTextMessages = contacts.some(x => x.optOutOfTextMessages === true) || student.optOutOfTextMessages === true

  return (
    <Card className={`card ${expanded ? 'expanded' : 'collapsed'}`} onClick={() => setExpanded(true)}>
      <div className='row'>
        <span className='cell title'>{firstName} {middleName} {lastName}</span>
        <span>
          <Button
            dense
            label='Delete'
            icon='delete'
            onClick={removeStudent}
          />
        </span>
      </div>
      <div className='row'>
        <span className='cell first'>Info</span>
        <span className='cell'>{gender}</span>
        <span className='cell'>{moment(birthDate).format('MM/DD/YYYY')}</span>
      </div>

      {expanded && (
        <>
          {student.contacts.filter(c => c.isPrimaryAdult).map((primary, i) =>
            <div className='row separator' key={`primary-${publicId}-${primary.firstName}-${primary.lastName}`}>
              <span className='cell first'>{`Primary Contact ${i > 0 ? i + 1 : ''}`}{primary.optOutOfTextMessages ? '*' : null}</span>
              <span className='cell'>{primary.firstName} {primary.lastName}</span>
              <span className='cell'>{primary.primaryPhone.number}</span>
            </div>
          )}
          <div className='row separator'>
            <span className='cell first'>Phone{optOutOfTextMessages ? ' *' : null}</span>
            <span className='cell-2'>{phoneNumber}</span>
          </div>
          <div className='row'>
            <span className='cell first'>Email</span>
            <span className='cell-2'>{email}</span>
          </div>
          <div className='row separator'>
            <span className='cell first'>Address</span>
            <span className='cell'>{address1}</span>
            <span className='cell'>{address2}</span>
          </div>
          <div className='row'>
            <span className='cell' />
            <span className='cell'>{city}</span>
            <span className='cell'>{state} {zipCode}</span>
          </div>
          <div className='row separator'>
            <span className='cell first'>Primary Language</span>
            <span className='cell-2'>{language}</span>
          </div>
          <div className='row separator'>
            <span className='cell first'>Highest Education Level</span>
            <span className='cell-2'>{educationLevel}</span>
          </div>
          <div className='row separator'>
            <span className='cell first'>Race</span>
            {student.races.map((race, index) => {
              return <span key={index} className='cell'>{race}</span>
            })}
          </div>
          <div className='row separator'>
            <span className='cell first'>Hispanic</span>
            <span className='cell-2'>{student.isHispanicLatino ? 'Yes' : 'No'}</span>
          </div>
          {student.isWedIndustryStudent &&
            <div className='row separator'>
              <span className='cell first'>Section Number</span>
              <span className='cell-2'>{student.sectionNumber}</span>
            </div>}
          {studentIsAdult && !studentIsSponsored &&
            <div className='row separator'>
              <span className='cell first'>Sponsored by Company</span>
              <span className='cell-2'>No</span>
            </div>}
          {studentIsAdult && studentIsSponsored &&
            <>
              <div className='row separator'>
                <span className='cell first'>Company Name</span>
                <span className='cell-2'>{sponsorshipInfo.companyName}</span>
              </div>
              <div className='row'>
                <span className='cell first'>Company Address</span>
                <span className='cell-2'>{sponsorshipInfo.companyAddress}</span>
              </div>
              <div className='row'>
                <span className='cell' />
                <span className='cell'>{sponsorshipInfo.companyCity}</span>
                <span className='cell'>{sponsorshipInfo.companyState} {sponsorshipInfo.companyZipCode}</span>
              </div>
              <div className='row'>
                <span className='cell first'>Company Gave Time Off</span>
                <span className='cell-2'>{sponsorshipInfo.companyGaveTimeOff ? 'Yes' : 'No'}</span>
              </div>
              <div className='row'>
                <span className='cell first'>Company Is Paying</span>
                <span className='cell-2'>{sponsorshipInfo.companyIsPaying ? 'Yes' : 'No'}</span>
              </div>
              <div className='row'>
                <span className='cell first'>Company Will Reimburse</span>
                <span className='cell-2'>{sponsorshipInfo.companyWillReimburse ? 'Yes' : 'No'}</span>
              </div>
              <div className='row'>
                <span className='cell first'>Is Self Employed</span>
                <span className='cell-2'>{sponsorshipInfo.selfEmployed ? 'Yes' : 'No'}</span>
              </div>
              {!sponsorshipInfo.selfEmployed &&
                <>
                  <div className='row'>
                    <span className='cell first'>Supervisor Name</span>
                    <span className='cell-2'>{sponsorshipInfo.supervisorName}</span>
                  </div>
                  <div className='row'>
                    <span className='cell first'>Supervisor Email</span>
                    <span className='cell-2'>{sponsorshipInfo.supervisorEmail}</span>
                  </div>
                  <div className='row'>
                    <span className='cell first'>Supervisor Phone Number</span>
                    <span className='cell-2'>{sponsorshipInfo.supervisorPhoneNumber}</span>
                  </div>
                </>}
            </>}
        </>
      )}

      {contacts.filter(c => c.isEmergencyContact).map((emergency, i) =>
        <div className='row separator' key={`emergency-${student.publicId}-${emergency.firstName}-${emergency.lastName}`}>
          <span className='cell first'>{`Emergency Contact ${i > 0 ? i + 1 : ''}`}{emergency.optOutOfTextMessages ? '*' : null}</span>
          <span className='cell'>{emergency.firstName} {emergency.lastName}</span>
          <span className='cell'>{emergency.primaryPhone.number}</span>
        </div>
      )}

      {expanded && (
        <div className='row separator'>
          <span className='cell first'>Notes</span>
          <span className='cell-2'>{notes}</span>
        </div>
      )}

      {expanded && hasOptedOutOfTextMessages && (
        <div className='row separator'>
          <span className='cell-2'><em>* has Opted out of Text Messages.</em></span>
        </div>
      )}

      {!expanded && (
        <div className='row'>
          <span className='more'>Click to expand<SVGIcon icon='more' className='more-icon' /></span>
        </div>
      )}
    </Card>
  )
}

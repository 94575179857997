import React, { useEffect, useContext } from 'react'
import Banner from '../Banner'
import { Grid, GridCell } from '@rmwc/grid'
import { useCourseCategories } from '~/refdata'
import { Link } from 'react-router-dom'
import TextField from '../TextField'
import { HeaderContext } from '../../App'
import Button from '../Button'

import './CourseCategoryPage.scss'

import { imageMap } from '~/images/course-category-images/image-map'

export default function CourseCategoryPage (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Class Category Search' })
  }, [])

  const courseCategories = useCourseCategories()

  return (
    <div className='course-category-page'>
      <Banner message='Class Categories' />

      <div className='course-category-page-body'>
        <div className='info-section'>
          <hr />
          <h2>Receive Updates About Classes</h2>
          <p>
            We are adding new classes daily. If you want to be the first to know,&nbsp;
            <a href='https://www.francistuttle.edu/featured-classes-newsletter-list'>sign up for our e-mail list&nbsp;</a>
            to receive updates about class offerings, featured classes, and special promotions.
          </p>
          <p>
            For enrollment assistance or questions, you can also call our enrollment number: <a href='tel:405.717.4900'>405.717.4900</a>.
          </p>

          <hr />
        </div>

        <form
          action='/ll-courses'
          onSubmit={(e) => {
            e.preventDefault()
            window.location.href = `#/ll-courses?${new URLSearchParams({ q: e.target.elements.query.value.slice(0, 100), c: 'All' })}`
          }}
        >
          <TextField
            className='course-search-field'
            name='query'
            label='Search all categories'
          />
          <Button
            outlined
            label='Search'
            type='submit'
            icon='search'
          />
        </form>

        <hr />

        <Grid className='course-category-grid'>
          {courseCategories.map((category, i) => (
            <>
              <GridCell key={i} span={6} tablet={12} align='middle'>
                <div>
                  <Link to={`ll-courses?${new URLSearchParams({ q: '', c: category.name })}`} className='course-category-link'>
                    <div>
                      <div>
                        <h2>{category.description} ({category.courseCount})</h2>
                        <div className='image-box'>
                          <img src={imageMap[category.name]} alt={category.description} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </GridCell>
            </>
          ))}
        </Grid>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import numeral from 'numeral'

import http from '~/http'
import { renderButtons, unloadButtons } from '~/helpers/loadPaypal'

async function preflight (props) {
  const payload = {
    cartPublicId: props.cart.publicId,
    total: props.cart.total
  }

  const response = await http(props.setGlobalError, http => http.post('/api/SelfService/Cart/Preflight', payload))
  props.dispatch({
    type: 'PREFLIGHT',
    data: {
      cart: response.data.cart,
      total: response.data.total
    }
  })
}

async function complete (props, payPalErrorRef, orderId) {
  const payload = {
    cartPublicId: props.cart.publicId,
    payPalOrderId: orderId,
    mailPermission: props.mailPermissionRef.current
  }

  payPalErrorRef.error = null

  try {
    await http(() => {}, http => http.post('/api/SelfService/Cart/Complete', payload))
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      // PayPal error, ask user to retry
      payPalErrorRef.error = err.response.data.message
    } else if (err.response != null && err.response.status === 424) {
      // PayPal error, do not retry
      payPalErrorRef.error = 'We were unable to confirm with your financial institution whether your payment was completed or not. We are temporarily reserving your enrollments until you call us at 405-717-4900.'
      payPalErrorRef.response = err.response.data.message
      console.error(err.response.data.message)
      props.setPaypalButtonDisabled(true)
    }
    throw err
  }
}

async function recordCaptureId (cartPublicId) {
  const payload = {
    cartPublicId
  }

  try {
    const response = await http(() => {}, http => http.post('/api/SelfService/Cart/RecordCaptureId', payload))
    return response.data.captureId
  } catch (err) {
    // Allow to continue, http() will notify Raygun if necessary
    // This will temporarily show the Transaction ID as "pending..." on the receipt page
    // FTTC will have to contact us (or we'll see it in Raygun) that an order doesn't have
    // a Transaction ID. We'll then use the PayPal API to fetch it and manually update it in the DB
    return 'pending...'
  }
}

function Paypal (props) {
  const [divId] = useState(() => Math.random().toString(36).slice(2))

  useEffect(() => {
    console.log('BUTTONS', divId)
    const total = numeral(props.cart.total).format('0.00')
    const items = props.cart.reservations.map(reservation => ({
      name: reservation.courseName.slice(0, 127),
      unit_amount: {
        currency_code: 'USD',
        value: numeral(reservation.section.price).format('0.00')
      },
      quantity: 1,
      description: `${reservation.type} Enrollment: ${reservation.studentName}, ${reservation.section.startDate}, ${reservation.section.startTime}`.slice(0, 127),
      sku: `SYN-${reservation.section.courseSectionNumber}`.slice(0, 127),
      category: 'DIGITAL_GOODS'
    }))
    // https://developer.paypal.com/docs/api/orders/v2/#definition-email
    const order = {
      intent: 'CAPTURE',
      application_context: {
        brand_name: 'Francis Tuttle Technology Center',
        landing_page: 'BILLING',
        shipping_preference: 'NO_SHIPPING',
        user_action: 'PAY_NOW'
      },
      purchase_units: [{
        reference_id: props.cart.publicId,
        amount: {
          currency_code: 'USD',
          value: total,
          breakdown: {
            item_total: {
              currency_code: 'USD',
              value: total
            }
          }
        },
        payment_instruction: {
          disbursement_mode: 'INSTANT'
        },
        description: 'Self Service',
        custom_id: 'Self Service',
        soft_descriptor: '',
        items
      }]
    }
    renderButtons(
      divId,
      props.cart.publicId,
      props.setPaypalButtonLoaded,
      props.setGlobalError,
      order,
      () => preflight(props),
      async (payPalErrorRef, orderId) => {
        try {
          props.setOverlay(true)
          await complete(props, payPalErrorRef, orderId)
          const captureId = await recordCaptureId(props.cart.publicId)
          setTimeout(() => props.setCaptureId(captureId), 500)
        } catch (err) {
          props.setOverlay(false)
          throw err
        }
      }
    )

    return () => unloadButtons(divId)
  }, [divId])

  return (
    <div id='paypal-parent-container' className='paypal-area row'>
      <div id={`paypal-parent-${divId}`} className='paypal-container' />
    </div>
  )
}

export default connect(state => ({
  defaultAddress: state.defaultAddress,
  defaultContact: state.defaultContact,
  cart: state.cart
}))(Paypal)

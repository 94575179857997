import { combineReducers } from 'redux'
import students from './students'
import defaultAddress from './defaultAddress'
import defaultContact from './defaultContact'
import cart from './cart'

// Add a reducer to this object to reset its state next time the user reloads the site
export const initialStates = {
  // add here as needed
}

// Increment this counter to wipe everybody's previous state
// This is used during development when making backwards-incompatible changes to the Redux state

export const latestStateVersion = 28

const rootReducer = combineReducers({
  students,
  defaultAddress,
  defaultContact,
  cart
})

export default function (state, action) {
  if (action.type === 'RESET_STATE') {
    state = {}
  }

  return rootReducer(state, action)
}

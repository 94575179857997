import React, { useState, useEffect, useContext } from 'react'
import { HeaderContext } from '../../App'
import Banner from '../Banner'
import Bottleneck from 'bottleneck/es5'
import qs from 'querystring'
import moment from 'moment'
import { request } from 'graphql-request'
import SearchPage from '~/components/shared/SearchPage'
import Checkbox from '../Checkbox'
import ErrorMessage from '~/components/ErrorMessage'
import Course from '~/components/sq/Course'
import { useWeeks, useTimeSlots, useLocations, useSeasonInfo } from '~/refdata'
import { formatDurationForDisplay } from '~/helpers/dateAndTime'

import './CourseSearchPage.scss'

// --vars
const gqlQuery = `
query ($searchText: String, $ageGroup: AgeGroups, $weeks: [Int!], $timeSlots: [Int!], $locations: [String!]) {
  sqCourses(searchText: $searchText, ageGroup: $ageGroup, weeks: $weeks, timeSlots: $timeSlots, locations: $locations) {
    courseName
    description
    id
    sections {
      id
      ageGroup
      availableCapacity
      campus
      capacity
      courseSectionNumber
      price
      timeSlotId
      weekId
      notEnrollableReason
    }
  }
}
`

const sectionsToHide = [] // NOTE: remove
const zone = 'America/Chicago'

const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 800,
  highWater: 1
})

// --components
export default function CourseSearchPage (props) {
  const weeks = useWeeks()
  const timeSlots = useTimeSlots()
  const allLocations = useLocations()
  const sqSeasonInfo = useSeasonInfo()

  const [locations, setLocations] = useState([])
  const [canEnroll, setCanEnroll] = useState()
  const [closeCards, setCloseCards] = useState(false)
  const [loading, setLoading] = useState(true)
  const [globalError, setGlobalError] = useState(null)
  const [courses, setCourses] = useState([])
  const [weeksSelected, setWeeksSelected] = useState({})
  const [locationsSelected, setLocationsSelected] = useState({})
  const [timeSlotsSelected, setTimeSlotsSelected] = useState({})
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('Default')

  const [showFilterWarning, setShowFilterWarning] = useState(false)

  const [query, setQuery] = useState(() => {
    const parsed = qs.parse(props.location.search.slice(1))
    return parsed.q == null ? '' : parsed.q
  })

  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Summer Quest Classes' })
  }, [])

  useEffect(() => {
    setWeeksSelected(allSelected(weeks))
  }, [weeks])

  useEffect(() => {
    setTimeSlotsSelected(allSelected(timeSlots))
  }, [timeSlots])

  useEffect(() => {
    setLocationsSelected(locations.map(x => x.name))
  }, [locations])

  useEffect(() => {
    setLocations(allLocations.filter(x => x.sqEligible))
  }, [allLocations])

  useEffect(() => {
    const weekIds = Object.keys(weeksSelected)
      .map(x => parseInt(x))
      .filter(x => weeksSelected[x])

    const timeSlotIds = Object.keys(timeSlotsSelected)
      .map(x => parseInt(x))
      .filter(x => timeSlotsSelected[x])

    const graphQlArgs = {
      searchText: query,
      ageGroup: selectedAgeGroup,
      weeks: weekIds,
      timeSlots: timeSlotIds,
      locations: locationsSelected
    }

    if (weekIds.length === 0 || timeSlotIds.length === 0 || locationsSelected.length === 0 || selectedAgeGroup == null) {
      setShowFilterWarning(true)
    } else {
      setShowFilterWarning(false)
    }

    onFilterChange(setLoading, setCloseCards, setCourses, setGlobalError, graphQlArgs, canEnroll)
  }, [query, weeksSelected, timeSlotsSelected, selectedAgeGroup, locationsSelected, canEnroll])

  useEffect(() => {
    props.history.push({ search: `?q=${query}` })
  }, [query])

  const checkEnrollmentStatus = () => {
    if (sqSeasonInfo != null) {
      const regStart = moment(sqSeasonInfo.registrationStarts)
      const now = moment()
      const canEnrollNow = now.isSameOrAfter(regStart)
      setCanEnroll(canEnrollNow)
    }
  }

  useEffect(() => {
    checkEnrollmentStatus()
    const interval = setInterval(() => {
      checkEnrollmentStatus()
    }, 5000)
    return () => clearInterval(interval)
  }, [sqSeasonInfo])

  const filterMarkup = (
    <Filters
      {...{
        weeks,
        setWeeksSelected,
        weeksSelected,
        locations,
        locationsSelected,
        setLocationsSelected,
        timeSlots,
        timeSlotsSelected,
        setTimeSlotsSelected,
        setSelectedAgeGroup,
        selectedAgeGroup
      }}
    />
  )

  return (
    <div className='summer-quest-course-search-page'>
      <Banner message='Summer Quest' />

      <div className='info-section'>
        <div className='paragraph'>
          Summer Quest programs offer students ages 11 to 15 the opportunity
          to take fun educational classes during the month of June.
        </div>

        <div className='paragraph'>
          This year, the Summer Quest program is offered in four weekly sessions from June 4th to June 28th
          from 9&nbsp;a.m. to 3&nbsp;p.m. On-campus classes take place all day, Tuesday through Friday, with
          the  exception of June 17-21. Due to the observed Juneteenth holiday on June 19th, that week classes
          will run Monday through Friday.
        </div>

        <div className='paragraph'>
          Tuition is $120 each week, and supplies are $25, due at the time of enrollment. To cancel, please let
          us know three business days before the class begins, and we will be happy to provide a refund for the
          tuition. The $25 supply cost is nonrefundable. If you drop an enrollment and wish to enroll in another
          class, we can transfer the cost (including supplies) to the new enrollment. Please note, if you cancel
          an enrollment within three business days of the first day of the class, no refund will be provided.
        </div>

        <div className='paragraph'>
          For any other questions related to Summer Quest enrollment, please call us at 405-717-4900.
        </div>
      </div>

      <SearchPage
        {...{ query, setQuery, loading, showFilterWarning }}
        filters={filterMarkup}
      >
        {
          (sqSeasonInfo && !loading && showFilterWarning) && <ErrorMessage warning message='It looks like you are missing some filter selections. Please check your filters to get results.' />
        }
        {
          (!sqSeasonInfo && !loading) && <ErrorMessage warning message='Summer Quest is now over. Join us next year for more Summer Quest!' />
        }
        <div className='results-count'>
          {
            (!loading && sqSeasonInfo != null) && (
              <>
                {`${courses.length} Result${courses.length === 1 ? '' : 's'}`}
                {courses.length < 1 && <div>Consider changing your search keywords and filters</div>}
              </>
            )
          }
          {globalError && <ErrorMessage message={globalError} />}
        </div>

        <div className='results'>
          {courses.map(course =>
            <Course
              key={`course-${course.id}`}
              course={course}
              goToAddStudent={() => {
                props.history.push('/addstudent')
                window.scrollTo(0, 0)
              }}
              {...{ closeCards, setCloseCards, history: props.history }}
            />
          )}
        </div>
      </SearchPage>
    </div>
  )
}

function Filters ({
  weeks,
  setWeeksSelected,
  weeksSelected,
  timeSlots,
  timeSlotsSelected,
  setTimeSlotsSelected,
  locations,
  locationsSelected,
  setLocationsSelected,
  setSelectedAgeGroup,
  selectedAgeGroup
}) {
  return (
    <div className='filters'>

      <div className='column weeks'>
        {locations.map(({ name }, idx) => (
          <div className='row' key={`${name}-${idx}`}>
            <Checkbox
              label={name}
              onChange={(evt) => {
                if (evt.target.checked) {
                  setLocationsSelected([...locationsSelected, name])
                } else {
                  setLocationsSelected(locationsSelected.filter(x => x !== name))
                }
              }}
              checked={locationsSelected.includes(name)}
            />
          </div>
        ))}
        {weeks.map(week => (
          <div className='row' key={`${week.name}-${week.id}`}>
            <Checkbox
              label={`
              ${week.name}
              ${moment(week.startDate).tz(zone).format('MM/DD')}
              -
              ${moment(week.endDate).tz(zone).format('MM/DD')}
            `}
              onChange={(evt) => {
                setWeeksSelected({ ...weeksSelected, [week.id]: evt.target.checked })
              }}
              checked={weeksSelected[week.id] === true}
            />
          </div>
        ))}
      </div>

      <div className='column'>
        {timeSlots.map(timeSlot => (
          <div className='row' key={`${timeSlot.name}-${timeSlot.id}`}>
            <Checkbox
              onChange={(evt) => {
                setTimeSlotsSelected({ ...timeSlotsSelected, [timeSlot.id]: evt.target.checked })
              }}
              label={`${timeSlot.name} ${formatDurationForDisplay(timeSlot.startTime)} - ${formatDurationForDisplay(timeSlot.endTime)}`}
              checked={timeSlotsSelected[timeSlot.id] === true}
            />
          </div>
        ))}
        <AgeGroupsFilter setSelectedAgeGroup={setSelectedAgeGroup} selectedAgeGroup={selectedAgeGroup} />
      </div>
    </div>

  )
}

function AgeGroupsFilter ({ setSelectedAgeGroup, selectedAgeGroup }) {
  const [younger, setYounger] = useState(true)
  const [older, setOlder] = useState(true)

  useEffect(() => {
    if (younger && older) {
      setSelectedAgeGroup('Default')
    } else if (!younger && !older) {
      setSelectedAgeGroup(undefined)
    } else if (younger) {
      setSelectedAgeGroup('ElevenToThirteen')
    } else {
      setSelectedAgeGroup('ThirteenToFifteen')
    }
  }, [younger, older])

  return (
    <>
      <div className='age-group'>Which apply to your students?</div>
      <div className='row'>
        <Checkbox
          onChange={(evt) => setYounger(evt.target.checked)}
          label='11-13 years old'
          checked={selectedAgeGroup === 'Default' || selectedAgeGroup === 'ElevenToThirteen'}
        />
      </div>
      <div className='row'>
        <Checkbox
          onChange={(evt) => setOlder(evt.target.checked)}
          label='13-15 years old'
          checked={selectedAgeGroup === 'Default' || selectedAgeGroup === 'ThirteenToFifteen'}
        />
      </div>
    </>
  )
}

// --functions
async function onFilterChange (setLoading, setCloseCards, ...args) {
  try {
    setCloseCards(true)
    await limiter.schedule(() => search(setLoading, ...args))
  } catch (err) {
    if (!(err instanceof Bottleneck.BottleneckError)) {
      // Ignore the intentional throttling rejections
      // throw err
      // FIXME: eat error for now
    }
  } finally {
    setCloseCards(false)
  }
}

async function search (setLoading, setCourses, setGlobalError, graphQlArgs, canEnroll) {
  // TODO: setGlobalError
  if (canEnroll === undefined |
    Object.keys(graphQlArgs.locations).length === 0 ||
    graphQlArgs.weeks.length === 0 ||
    graphQlArgs.timeSlots.length === 0) {
    setCourses([])
    setLoading(false)
    return
  }
  setLoading(true)
  const response = await request('/api/public/graphql', gqlQuery, graphQlArgs)
  setCourses(response.sqCourses.map((course) => {
    course.sections = course.sections.filter((section) => {
      return sectionsToHide.indexOf(section.courseSectionNumber) < 0
    })
    return course
  }).filter((course) => course.sections.length > 0))
  setLoading(false)
}

// This one sets
function allSelected (collection) {
  return collection
    .reduce((acc, x) => (
      { ...acc, [x.id]: true }
    ), {})
}

// --mapping
